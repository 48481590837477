<template>
  <v-container id="content">
    <v-form v-model="formValid" @submit.prevent="save">
      <v-card >
        <v-card-title>
          {{ $t("myProfile") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="mt-4"
            v-model="name"
            maxlength="50"
            outlined
            :label="$t('name')"
            :rules="requiredRules"
            data-testid="nameUserProfile"
          />
          <PhoneNumberInput
            v-if="phoneObj"
            :country-code="phoneObj.countryCode"
            :number="phoneObj.number"
            :label="$t('mobile')"
            :phone-type="phoneObj.type"
            v-on:change="setPhone"
            data-testid="numberUserProfile"
            :hide-phone-type="true"
            :hide-phone-number-type="true"
          />
          <v-text-field
            class="mt-6"
            :label="$t('email')"
            type="email"
            v-model="email"
            outlined
            append-icon="mdi-email"
            maxlength="200"
            :rules="requiredEmailRules"
            data-testid="emailUserProfile"
          />
          <v-btn @click="dialogPass = true" data-testid="passwordUserProfile">
            {{ $t("changePassword") }}
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" :disabled="!formValid" class="primary" data-testid="saveUserProfile">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="dialogPass" v-if="dialogPass" max-width="550" persistent>
      <v-card>
        <v-card-title>
          {{ $t("changePassword") }}
        </v-card-title>
        <v-card-text>
          <PasswordCreator
            :init-email="email"
            :hide-email="true"
            v-on:success="passwordChanged"
          >
            <template v-slot:enterEmailText>
              {{ $t("pressButtonToGetCode") }}.
              {{ $t("codeSentToYourMobileX", { mobile: mobile }) }}.
            </template>
          </PasswordCreator>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogPass = false" data-testid="cancelPWUserProfile">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PhoneNumberInput from "@/components/misc/PhoneNumberInput";
import PhoneNumber from "@/util/PhoneNumber";
import formValidationRules from "@/util/formValidationRules";
import user from "@/util/user";
import appSnackbar from "@/util/appSnackbac";
import PasswordCreator from "@/components/auth/PasswordCreator";
import store from "@/store";

export default {
  name: "UserProfile",
  components: { PasswordCreator, PhoneNumberInput },

  data: () => ({
    dialogPass: false,
    name: "",
    formValid: true,
    email: "",
    phoneObj: null,
    mobile: "",
    requiredEmailRules: formValidationRules.requiredEmailRules,
    requiredRules: formValidationRules.requiredRule
  }),

  created() {
    this.update(true);
  },

  methods: {
    passwordChanged() {
      appSnackbar.setMessage(this.$t("passwordChanged"));
      this.dialogPass = false;
    },
    update(forceServerFetch) {
      store.dispatch("updateUserProfile", forceServerFetch).then(profile => {
        this.name = profile.Bruker.BrukerNavn;
        this.email = profile.Bruker.Epost;
        const pn = new PhoneNumber(profile.Bruker.Mobilnr);
        this.phoneObj = pn.getAsApiFormat();
      });
    },
    setPhone(phoneProps) {
      this.mobile = phoneProps.countryCode + phoneProps.number;
    },
    save() {
      user.changeProfile(this.name, this.email, this.mobile).then(() => {
        this.update();
        appSnackbar.setMessage(this.$t("profileSaved"));
      });
    }
  }
};
</script>
<style scoped>
#content {
  max-width: 900px;
}
</style>
