<template>
  <div>
    <v-btn @click="openDialog()" block x-large class="btn" data-testid='CountryPhoneCodeSelectBtn' >
      {{ countryCode.phone }}
      <img :src="flagFile" class="flag" alt="" />
      <img
          v-if="secondaryFlagFile"
          :src="secondaryFlagFile"
          class="flag"
          alt=""
      />
    </v-btn>

    <v-dialog v-model="dialog" max-width="550">
      <v-card>
        <v-card-title>
          {{ $t("selectCountryCode") }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              data-testid='CountryPhoneCodeSelectList'
              :items="countryCodes"
              v-model="countryCode"
              @change="changeCode($event)"
              outlined
              prepend-inner-icon="mdi-earth"
              item-text="name"
              return-object
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog = false">{{ $t("cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import countryCodes from "../../util/country-codes.json";

export default {
  name: "CountryPhoneCodeSelect",

  props: {
    initValue: {
      type: String,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    countryCodes: null,
    countryCode: null,
    flagFile: "",
    secondaryFlagFile: ""
  }),

  created() {
    this.countryCodes = countryCodes;
    this.countryCode = findCountryCode(this.initValue);
    this.setFlag(this.countryCode.id);
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },
    changeCode(event) {
      this.setFlag(event.id)
      this.$emit("change", event.phone);
      this.dialog = false;
    },
    setFlag(countryId) {
      this.flagFile = getFlagFile(countryId);
      this.setSecondaryFlag(countryId)
    },
    setSecondaryFlag(countryId) {
      // USA and Canada both use +1 as int. phone prefix; show both flags
      const usa = "US";
      const canada = "CA";
      if (countryId === usa) {
        this.secondaryFlagFile = getFlagFile(canada);
      } else if (countryId === canada) {
        this.secondaryFlagFile = getFlagFile(usa);
      } else {
        this.secondaryFlagFile = "";
      }
    }
  }
};
const findCountryCode = countryCodeNum => {
  return countryCodes.find(element => element.phone === countryCodeNum);
};

const getFlagFile = countryId => {
  countryId = countryId.toLowerCase();
  return require(`@/assets/images/flags/${countryId}.svg`);
};
</script>

<style scoped>
.btn {
  margin-top: 2px;
}
.flag {
  width: 24px;
  margin-left: 4px;
}
</style>
