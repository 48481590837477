<template>
  <div>
    <v-row class="mb-n8">
      <v-col cols="12" xs="12" md="3">
        <CountryPhoneCodeSelect
          :init-value="phone.countryCode"
          v-on:change="changePhoneCountryCode($event)"
        />
      </v-col>
      <v-col cols="12" xs="12" :md="showPhoneNumerTypeInput ? 5 : 9">
        <v-text-field
          v-model="phone.number"
          :label="phone.label"
          type="tel"
          outlined
          :append-icon="icon"
          maxlength="16"
          @input="usrInput"
          :rules="phoneRules"
          data-testid="phoneNumberInputNumber"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xs="12" md="4" v-if="showPhoneNumerTypeInput">
        <v-select
          v-model="phone.numberType"
          outlined
          :label="$t('type')"
          @change="emitChange"
          :items="[
            { text: $t('unspecified'), value: 0 },
            { text: $t('work'), value: 1 },
            { text: $t('private'), value: 2 }
          ]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="phone.countryCode !== '+47'" class="mt-n10">
      <v-col cols="4"></v-col>
      <v-col cols="8" v-if="!hidePhoneType">
        <v-radio-group
          v-if="phone.number"
          v-model="phone.type"
          @change="emitChange"
          dense
          row
          class="mb-n6"
          :rules="requiredFieldRule"
        >
          <v-radio :label="$t('mobile')" value="M"></v-radio>
          <v-radio :label="$t('landline')" value="F"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CountryPhoneCodeSelect from "@/components/contacts/CountryPhoneCodeSelect";
import PhoneNumber from "@/util/PhoneNumber";
import formValidationRules from "@/util/formValidationRules";
import store from "@/store";

export default {
  name: "PhoneNumberInput",
  components: { CountryPhoneCodeSelect },

  props: {
    countryCode: {
      type: String,
      required: true
    },
    number: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "mdi-cellphone"
    },
    // "M" of "F"
    phoneType: {
      type: String,
      default: ""
    },
    // 0 (unknown), 1 (work), or 2 (private)
    numberType: {
      type: Number,
      default: 1
    },
    hidePhoneType: {
      type: Boolean,
      default: false
    },
    hidePhoneNumberType: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showPhoneNumerTypeInput() {
      if (!store.state.userProfile?.Bruker._permissions.phoneNumTypes) {
        return false;
      }
      return !this.hidePhoneNumberType;
    },
  },

  data: () => ({
    phone: {
      countryCode: "",
      number: "",
      label: "",
      type: "",
      numberType: 1
    },
    phoneRules: formValidationRules.phoneRules,
    requiredFieldRule: formValidationRules.requiredRule
  }),

  created() {
    this.phone.countryCode = this.countryCode;
    this.phone.number = this.number;
    this.phone.label = this.label;
    this.phone.type = this.phoneType;
    this.phone.numberType = this.numberType;
    this.emitChange();
  },

  methods: {
    setPhone() {
      this.phone.countryCode = this.countryCode;
      this.phone.number = this.number;
      this.phone.label = this.label;
      this.phone.type = this.type;
      this.checkPhoneType();
      this.emitChange();
    },
    changePhoneCountryCode(newCode) {
      this.phone.countryCode = newCode;
      this.checkPhoneType();
      this.emitChange();
    },
    usrInput() {
      this.checkPhoneType();
      this.emitChange();
    },
    checkPhoneType() {
      const phoneNumber = new PhoneNumber(
        this.phone.countryCode + this.phone.number
      );
      if (!phoneNumber.getPhoneNumProperties()) {
        return "";
      }
      this.phone.type = phoneNumber.isMobile(true) ? "M" : "F";
    },
    emitChange() {
      this.$emit("change", this.phone);
    }
  }
};
</script>
